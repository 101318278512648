<template>
	<div class="settings">
		<div class="settings-wrapper">
			<div class="settings-menu">
				<div class="section-title">Настройки</div>
				<ul class="settings-menu__list">
					<template v-for="itm in menu" :key="itm.url">
						<li v-if="itm.isShow" class="settings-menu__item">
							<router-link :to="`${itm.url}`" active-class="active">
								<img :src="itm.icon" alt="icon" decoding="async">
								{{ itm.title }}
							</router-link>
						</li>
					</template>
					<li class="settings-menu__item">
						<a href="" @click.prevent="logout" active-class="active">
							<img src="/images/out.svg" alt="icon" decoding="async">
							Выйти
						</a>
					</li>
				</ul>
			</div>

			<router-view />

			<!--
			<div class="settings-payment" v-if="protectedCurrentStep == 5">
				<h2 class="section-title">Оплата</h2>
				<div class="buttons">
					<button
						:class="{ active: activeButton === 1 }"
						@click="activeButton = 1"
					>
						<span class="desktop-text">Способы оплаты</span>
						<span class="mobile-text">Оплата</span>
					</button>
					<button
						:class="{ active: activeButton === 2 }"
						@click="activeButton = 2"
					>
						Вывод <span class="desktop-text">средств</span>
					</button>
					<button
						:class="{ active: activeButton === 3 }"
						@click="activeButton = 3"
					>
						История <span class="desktop-text">платежей</span>
					</button>
				</div>
				<form
					action="#"
					class="settings-payment__fieldset settings-payment__method"
					v-if="activeButton === 1"
				>
					<legend>Новая карта</legend>
					<div class="settings-payment__card">
						<label for="card-number">Номер карты</label>
						<input type="text" id="card-number" autocomplete="off" />
						<div class="label-wrapper">
							<label for="card-to-month">Срок действия</label>
							<label for="card-cvc">СVC</label>
						</div>
						<div class="wrap">
							<div class="period-wrapper">
								<input
									type="text"
									id="card-to-month"
									autocomplete="off"
								/>
								<span class="separator">/</span>
								<input
									type="text"
									id="card-to-year"
									autocomplete="off"
								/>
							</div>
							<input type="text" id="card-cvc" />
						</div>
					</div>
					<button
						class="btn settings-payment__submit-btn"
						type="submit"
					>
						Сохранить
					</button>
				</form>
				<form
					class="settings-payment__withdrawal"
					v-if="activeButton === 2"
				>
					<fieldset class="settings-payment__fieldset">
						<legend>Способы вывода</legend>
						<div class="withdrawal-img">
							<img
								src="@/assets/images/Example_4.png"
								alt="Изображение карты в руке"
							/>
						</div>
						<p class="withdrawal-cta">
							Привяжите карту. <br />
							Это удобно!
						</p>
						<button
							class="btn"
							type="button"
							style="width: 310px; max-width: 100%"
						>
							Привязать карту
						</button>
					</fieldset>
					<fieldset
						class="
							settings-payment__fieldset settings-payment__withdrawal
						"
					>
						<legend>Вывод средств</legend>
						<el-select
							class="select card-select"
							v-model="payCardsValue"
							popper-class="select__options-dropdown"
							placeholder="Выберите карту"
						>
							<el-option
								class="select__option"
								v-for="item in payCards"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							>
							</el-option>
						</el-select>
						<button
							class="btn"
							type="button"
							style="width: 100%; max-width: 310px"
						>
							Привязать другую карту
						</button>
					</fieldset>
				</form>
				<form
					class="settings-payment__transactions"
					v-if="activeButton === 3"
				>
					<h3>История платежей</h3>
					<input
						class="transactions__search-input"
						type="text"
						placeholder="Поиск"
					/>
					<div class="transactions__wrapper">
						<fieldset class="transactions__fieldset">
							<legend>Операции</legend>
							<ul class="transactions__list">
								<li class="transactions__item">
									<label for="booking-cancellation" class="text"
										>Отмена бронирования</label
									>
									<div class="switcher">
										<input
											class="tgl tgl-light"
											id="booking-cancellation"
											type="checkbox"
										/>
										<label
											class="tgl-btn"
											for="booking-cancellation"
										></label>
									</div>
								</li>
								<li class="transactions__item">
									<label for="booking" class="text">Бронирование</label>
									<div class="switcher">
										<input
											class="tgl tgl-light"
											id="booking"
											type="checkbox"
										/>
										<label class="tgl-btn" for="booking"></label>
									</div>
								</li>
								<li class="transactions__item">
									<label for="withdrawal" class="text"
										>Списание со счета
									</label>
									<div class="switcher">
										<input
											class="tgl tgl-light"
											id="withdrawal"
											type="checkbox"
										/>
										<label class="tgl-btn" for="withdrawal"></label>
									</div>
								</li>
								<li class="transactions__item">
									<label for="deposit" class="text"
										>Пополнение счета
									</label>
									<div class="switcher">
										<input
											class="tgl tgl-light"
											id="deposit"
											type="checkbox"
										/>
										<label class="tgl-btn" for="deposit"></label>
									</div>
								</li>
							</ul>
							<button
								class="btn"
								type="button"
								style="width: 100%; max-width: 310px"
							>
								Применить
							</button>
						</fieldset>
						<fieldset class="amount__fieldset">
							<legend>Сумма</legend>
							<label for="amount-from"
								>от
								<input
									id="amount-from"
									type="number"
									v-model="amountValue[0]"
								/>
							</label>
							<label for="amount-to"
								>до
								<input
									id="amount-to"
									type="number"
									v-model="amountValue[1]"
								/>
							</label>
							<div class="el-slider__wrapper">
								<el-slider
									v-model="amountValue"
									range
									:show-tooltip="false"
									:step="500"
									:max="25000"
								>
								</el-slider>
							</div>
							<button
								class="btn"
								type="button"
								style="width: 100%; max-width: 310px"
							>
								Применить
							</button>
						</fieldset>
					</div>
				</form>
			</div> -->
		</div>
	</div>
</template>

<script>
import { LOGOUT } from '@/constants/store/auth/actions'

const menu = [
	// { title: 'Уведомления', url: 'notification', icon: '/images/notification.svg', isShow: true },
	{ title: 'Пользовательское соглашение', url: '/privacy', icon: '/images/policy.svg', isShow: true },
	{ title: 'Лицензионное соглашение', url: '/license-guest', icon: '/images/terms.svg', isShow: true },
  { title: 'Лицензионное соглашение', url: '/license-housekeeper', icon: '/images/terms.svg', isShow: true },
	{ title: 'Привязать социальные сети', url: 'socials', icon: '/images/socials.svg', isShow: false },
	{ title: 'Оплата и вывод средств', url: 'payments', isShow: false },
	{ title: 'Переключиться на поиск жилья', url: 'searching', isShow: false },
	// { title: 'Выйти', url: 'out', icon: '/images/out.svg', isShow: true }
]

export default {
	name: 'Settings',
	computed: {
    isMaster() {
      return this.$store.getters.isMaster
    },
    isGuest() {
      return this.$store.getters.isGuest
    },
		menu() {
			return [
        // { title: 'Уведомления', url: 'notification', icon: '/images/notification.svg', isShow: true },
        { title: 'Пользовательское соглашение', url: '/privacy', icon: '/images/policy.svg', isShow: true },
        { title: 'Лицензионное соглашение', url: '/license-guest', icon: '/images/terms.svg', isShow: this.isGuest },
        { title: 'Лицензионное соглашение', url: '/license-housekeeper', icon: '/images/terms.svg', isShow: this.isMaster },
        { title: 'Привязать социальные сети', url: 'socials', icon: '/images/socials.svg', isShow: false },
        { title: 'Оплата и вывод средств', url: 'payments', isShow: false },
        { title: 'Переключиться на поиск жилья', url: 'searching', isShow: false },
        // { title: 'Выйти', url: 'out', icon: '/images/out.svg', isShow: true }
      ]
		}
	},
	methods: {
		logout() {
			this.$store.dispatch(LOGOUT)
		}
	}
}
</script>

<style lang="sass" scoped>
.settings
	min-height: 800px
</style>

<style lang="sass">
.settings
	position: relative
	display: flex
	flex-direction: column
	max-width: 970px
	.section-title
		font-style: normal
		font-weight: 700
		font-size: 24px
		line-height: 24px
		display: flex
		align-items: center
		color: #3B3F47

	&-wrapper
		display: flex
	&-notifications,
	&-socials,
	.transactions
		width: 310px
		&__fieldset
			margin-bottom: 30px
			legend
				margin-bottom: 10px
				font-size: 16px
				line-height: 18px
		&__list
			list-style: none
			padding-left: 0
			margin: 0
			background: #ffffff
			border-radius: 10px
			box-shadow: 0px 5px 9px rgba(95, 83, 80, 0.1), 0px 5px 14px rgba(0, 0, 0, 0.1)
		&__item
			display: flex
			align-items: center
			justify-content: space-between
			padding: 6px 10px 6px 15px
			font-size: 12px
			line-height: 14px
			height: 40px
			.text
				padding-right: 20px
				margin-bottom: 0
			&:not(:last-child)
				border-bottom: 0.3px solid #e2e2e2
	&-socials
		width: 320px
		&__fieldset
			legend
				font-size: 14px
				line-height: 16px
				color: #CBCBCB
	&-privacy,
	&-agreement
		max-width: 420px
		&__text
			font-size: 14px
			line-height: 16px
	&-payment
		width: 640px
		.buttons
			display: flex
			justify-content: space-between
			margin-bottom: 30px
			button
				width: 31%
				height: 55px
				font-size: 12px
				line-height: 12px
				border-radius: 12px
				border: none
				box-shadow: 0px 0px 17px -6px lightgrey
				transition: 0.1s linear
				padding-left: 5%
				span.mobile-text
					display: none
				&:nth-child(1)
					background: url("~@/assets/images/svg/dollar-black.svg") 17% 50% no-repeat #ffffff
				&:nth-child(2)
					background: url("~@/assets/images/svg/download-black.svg") 17% 50% no-repeat #ffffff
				&:nth-child(3)
					background: url("~@/assets/images/svg/clock-black.svg") 17% 50% no-repeat #ffffff
				&.active,
				&:hover
					color: #ffffff
					background-color: #5F5350
					&:nth-child(1)
						background-image: url("~@/assets/images/svg/dollar-white.svg")
					&:nth-child(2)
						background-image: url("~@/assets/images/svg/download-white.svg")
					&:nth-child(3)
						background-image: url("~@/assets/images/svg/clock-white.svg")
		&__card
			position: relative
			width: 335px
			height: 210px
			padding: 40px 16px 20px 16px
			border-radius: 13px
			background: url("~@/assets/images/card-bg.jpg") center no-repeat
			z-index: 1
			margin-bottom: 35px
			&:before
				content: ''
				position: absolute
				z-index: 0
				top: 0
				left: 0
				width: 100%
				height: 100%
				background: rgba(224, 224, 224, 0.43)
				border: 2px solid rgba(245, 245, 245, 0.41)
				border-radius: 13px
			label
				position: relative
				font-weight: 500
				font-size: 13px
				line-height: 16px
			#card-number, #card-cvc
				position: relative
				display: block
				background: rgba(248, 248, 248, 0.4)
				border-radius: 11px
				height: 38px
				border: none
			#card-number
				display: block
				width: 100%
				border: 1px solid #000000
				font-size: 22px
				line-height: 26px
				text-align: center
				margin-bottom: 26px
			.label-wrapper
				display: flex
				justify-content: space-between
			label[for=card-cvc]
				margin-right: 30px
			.wrap
				display: flex
				justify-content: space-between
			#card-to-month, #card-to-year, #card-cvc
				font-size: 15px
				line-height: 18px
				font-weight: bold
			.separator
				font-weight: bold
				font-size: 15px
				line-height: 18px
			.period-wrapper
				position: relative
				display: flex
				align-items: center
				width: 86px
				background: rgba(248, 248, 248, 0.4)
				border-radius: 11px
				height: 38px
			#card-to-month, #card-to-year
				width: 48%
				background: transparent
				border: none
				text-align: center
			#card-cvc
				width: 61px
				text-align: center
		&__submit-btn
			max-width: 335px
			width: 100%
		.withdrawal-img
			margin-bottom: 12px
			width: 276px
			height: 200px
			border-radius: 11px
			img
				width: 100%
				object-fit: cover
		.withdrawal-cta
			font-weight: 600
			font-size: 18px
			line-height: 22px
			color: #000000
			margin-bottom: 30px
		.card-select
			width: 100%
			max-width: 310px
			display: block
			margin-bottom: 250px
		&__transactions
			h3
				font-weight: 600
				font-size: 16px
				line-height: 18px
				margin-bottom: 15px
			.transactions
				&__search-input
					border: 1px solid #CBCBCB
					border-radius: 12px
					width: 100%
					height: 55px
					padding: 20px 20px 20px 50px
					margin-bottom: 30px
					caret-color: #1AC386
					background: url("~@/assets/images/svg/magnifying-glass.svg") 15px 50% no-repeat #ffffff
				&__wrapper
					display: grid
					grid-template-columns: repeat(2, 310px)
					grid-column-gap: 20px
				&__list
					margin-bottom: 30px
			.amount__fieldset
				label
					color: rgba(#000000, 0.3)
					font-size: 15px
					line-height: 19px
					margin-right: 5px
					margin-bottom: 15px
					input
						width: 75px
						border: none
						text-align: center
						font-size: 14px
						line-height: 14px
						border-radius: 6px
						box-shadow: 0px 5px 12px rgba(95, 83, 80, 0.1)
						padding: 7px
					#amount-from
						margin-right: 14px
				.el-slider__wrapper
					background-color: #ffffff
					box-shadow: 0px 0px 17px -6px lightgrey
					padding: 13px 20px
					margin-bottom: 30px
					border-radius: 12px
				.el-slider
					&__runway
						background-color: #C7c7c7
						height: 2px
					&__bar
						background-color: #5F5350
						height: 2px
					&__button-wrapper
						top: -17px
					&__button
						border: 1px solid rgba(0, 0, 0, 0.04)
						box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.05)
						width: 25px
						height: 25px
.settings-menu
  margin-right: 60px
  max-width: 400px

// 1080
@media screen and (max-width: 1440px)
  .settings
		&-payment
			width: 460px
			.transactions
				&__wrapper
					grid-template-columns: unset
			.buttons
				button
					&:nth-child(1),
					&:nth-child(2)
						background-position: 10% 50%
					&:nth-child(3)
						background-position: 6% 50%
  .settings-menu
    max-width: 100%
// 996
@media screen and (max-width: 1180px)
	.settings
		&-payment
			width: 425px
			.buttons
				button
					&:nth-child(3)
						background-position: 3% 50%

// 900
@media screen and (max-width: 1024px)
	.settings
		&-agreement,
		&-privacy
			max-width: 350px
		&-payment
			width: 360px
			.buttons
				button
					span.desktop-text
						display: none
					span.mobile-text
						display: block
					&:nth-child(3)
						background-position: 10% 50%

// 768
@media screen and (max-width: 912px)
	.settings
		&-notifications,
		&-socials,
		&-privacy,
		&-agreement
			max-width: 450px

// 540
@media screen and (max-width: 800px)
	.settings
		width: 100%
		&-wrapper
			justify-content: center
		&-notifications,
		&-socials,
		&-privacy,
		&-agreement
			max-width: unset
			width: 100%
		&-payment
			width: 100%
			&__card
				width: 100%
				max-width: 335px
				min-width: 280px

.settings-menu
	width: 100%
	position: relative
  margin-right: 60px

	@media screen and (max-width: 913px)
		min-width: 200px
		margin-right: 20px

	&__list
		margin-top: 20px
		padding-left: 0
		margin-bottom: 0
		list-style: none
	&__item
		position: relative
		width: 100%
		height: 55px
		border-radius: 12px
		box-shadow: 0px 0px 17px -6px lightgrey
		background: url("~@/assets/images/svg/arrow-right.svg") no-repeat #ffffff
		background-position: calc(100% - 15px), calc(100% - 21px)
		transition: 0.1s linear
		a
			display: flex
			align-items: center
			width: 100%
			height: 100%
			font-size: 12px
			text-decoration: none
			color: #3B3F47
			line-height: 12px
			padding: 20px 15px 20px 45px
			border-radius: 12px
			&.active
				background: url("~@/assets/images/svg/arrow-right-white.svg") no-repeat #1AC386
				background-position: calc(100% - 15px), calc(100% - 21px)
				cursor: pointer
				color: #ffffff
			img
				position: absolute
				top: 18px
				left: 15px
			// &:before
			// 	position: absolute
			// 	top: 18px
			// 	left: 15px
			// &:first-child
			// 	&:before
			// 		content: url("~@/assets/images/svg/bell-icon-grey.svg")
			// 	&.active,
			// 	&:hover
			// 		&:before
			// 			content: url("~@/assets/images/svg/bell-icon-white.svg")
			// &:nth-child(2)
			// 	&:before
			// 		content: url("~@/assets/images/svg/shield-icon-grey.svg")
			// 	&.active,
			// 	&:hover
			// 		&:before
			// 			content: url("~@/assets/images/svg/shield-icon-white.svg")
			// &:nth-child(3)
			// 	&:before
			// 		content: url("~@/assets/images/svg/filetext-icon-grey.svg")
			// 	&.active,
			// 	&:hover
			// 		&:before
			// 			content: url("~@/assets/images/svg/filetext-icon-white.svg")
			// &:nth-child(4)
			// 	&:before
			// 		content: url("~@/assets/images/svg/globe-icon-grey.svg")
			// 	&.active,
			// 	&:hover
			// 		&:before
			// 			content: url("~@/assets/images/svg/globe-icon-white.svg")
			// &:nth-child(5)
			// 	&:before
			// 		content: url("~@/assets/images/svg/credit-сard-icon-grey.svg")
			// 	&.active,
			// 	&:hover
			// 		&:before
			// 			content: url("~@/assets/images/svg/credit-сard-icon-white.svg")
			// &:nth-child(6)
			// 	&:before
			// 		content: url("~@/assets/images/svg/switcher-icon-grey.svg")
			// 	&.active,
			// 	&:hover
			// 		&:before
			// 			content: url("~@/assets/images/svg/switcher-icon-white.svg")
			// &:last-child
			// 	&:before
			// 		content: url("~@/assets/img/svg/logout-icon-grey.svg")
			// 	&.active,
			// 	&:hover
			// 		&:before
			// 			content: url("~@/assets/img/svg/logout-icon-white.svg")
		&:not(:last-child)
			margin-bottom: 10px
		&.active,
		&:hover
			background: url("~@/assets/images/svg/arrow-right-white.svg") no-repeat #1AC386
			background-position: calc(100% - 15px), calc(100% - 21px)
			cursor: pointer
			a
				color: #ffffff
@media screen and (max-width: 500px)
	.settings-wrapper
		flex-wrap: wrap

		.settings-menu
			margin: 0 0 50px 0
</style>
